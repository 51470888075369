import { PostHog } from 'posthog-js';
import { getBrowserId, getSessionId } from '@/utils/getSessionId';

export const registerPosthog = (posthog: PostHog) => {
  console.log('registerPosthog');
  posthog.register({
    qura_browser_id: getBrowserId(),
    qura_session_id: getSessionId(),
  });
};
