import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
  QueryDocumentSnapshot,
  startAfter,
} from 'firebase/firestore';
import { useInfiniteQuery } from '@tanstack/react-query';
import { db } from '../firebaseConfig';
import { resultDocumentSchema, searchParamsSchema } from '@/services/api/apiValidation';
import { catchAndLogError } from './sendErrorLog';
import { z } from 'zod';
import { convertSnakeToCamelCaseObject } from '@/utils/snakeToCamelCase';
import { getAuth } from 'firebase/auth';

const searchActionSchema = z
  .object({
    submitted_at: z.number(),
    content: searchParamsSchema,
    search_id: z.string(),
  })
  .transform(convertSnakeToCamelCaseObject);

const searchHistoryOptionSchema = z.object({
  search: searchActionSchema,
  documents: z.array(resultDocumentSchema),
});

export type SearchHistoryOption = z.infer<typeof searchHistoryOptionSchema>;

export async function getFullHistorySearches(lastDoc: QueryDocumentSnapshot | null) {
  const user = getAuth().currentUser;

  if (!user) {
    throw new Error('No user found');
  }

  const searchesRef = collection(db, 'searches');
  let q = query(
    searchesRef,
    where('user_id', '==', user.uid),
    orderBy('created_at', 'desc'),
    limit(20),
  );

  if (lastDoc) {
    q = query(q, startAfter(lastDoc));
  }

  const searchesSnapshot = await getDocs(q);

  const searches: SearchHistoryOption[] = [];

  if (searchesSnapshot.empty) {
    return { searches, pageParam: null };
  }

  const lastVisible = searchesSnapshot.docs[searchesSnapshot.docs.length - 1];

  await Promise.all(
    searchesSnapshot.docs.map(async (searchDoc) => {
      const actionsRef = collection(searchDoc.ref, 'actions');
      const lastActionSnapshot = await getDocs(
        query(
          actionsRef,
          where('content_type', '==', 'search'),
          orderBy('submitted_at', 'desc'),
          limit(1),
        ),
      );

      if (!lastActionSnapshot.empty) {
        const viewedDocumentsRef = collection(searchDoc.ref, 'viewedDocuments');
        const viewedDocumentsSnapshot = await getDocs(viewedDocumentsRef);

        const data = {
          search: lastActionSnapshot.docs[0].data(),
          documents: viewedDocumentsSnapshot.empty
            ? []
            : viewedDocumentsSnapshot.docs.map((doc) => doc.data()),
        };

        searches.push(searchHistoryOptionSchema.parse(data));
      } else {
        throw new Error('No actions found for search');
      }
    }),
  );

  return { searches, pageParam: lastVisible };
}

export const useFullHistoryInfiniteScroll = () => {
  return useInfiniteQuery({
    initialPageParam: null as QueryDocumentSnapshot | null,
    queryKey: ['recent_searches'],
    queryFn: ({ pageParam }) => catchAndLogError(() => getFullHistorySearches(pageParam)),
    getNextPageParam: (lastPage) => lastPage.pageParam,
  });
};
