import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
  doc,
  getDoc,
  DocumentReference,
} from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { searchParamsSchema, webSocketOutputSchema } from '@/services/api/apiValidation';
import devlog from '@/utils/devlog';

export async function getOldSearch(searchId: string) {
  const docRef = doc(db, 'searches', searchId);
  const document = await getDoc(docRef);
  devlog(document);
  devlog('Got search');
  if (document == undefined) {
    devlog('SEARCH IS UNDEFINED');
  }
  const latestResult = document.get('latest_result');
  if (latestResult == undefined) {
    devlog('NO LATEST RESULT');
  }
  if (!(latestResult instanceof DocumentReference)) {
    devlog('latest result is of invalid type', typeof latestResult);
  }

  const lastOutputSnapshot = await getDoc(latestResult);

  devlog('Got last output');
  const output = webSocketOutputSchema.parse(lastOutputSnapshot.data());

  const actionRef = collection(docRef, 'actions');
  const lastAction = query(
    actionRef,
    where('content_type', '==', 'search'),
    orderBy('submitted_at', 'desc'),
    limit(1),
  );
  const lastActionSnapshot = await getDocs(lastAction);
  const action = lastActionSnapshot.docs[0].data();
  const submittedParams = searchParamsSchema.parse(action['content']);

  if (output.contentType !== 'result') {
    throw new Error('No actions found for search');
  }

  return {
    result: output.content,
    submittedParams,
  };
}

('The query requires an index. You can create it here: https://console.firebase.google.com/v1/r/project/qura-dev-3/firestore/indexes?create_composite=Cktwcm9qZWN0cy9xdXJhLWRldi0zL2RhdGFiYXNlcy8oZGVmYXVsdCkvY29sbGVjdGlvbkdyb3Vwcy9zZWFyY2hlcy9pbmRleGVzL18QARoLCgd1c2VyX2lkEAEaDgoKY3JlYXRlZF9hdBACGgwKCF9fbmFtZV9fEAI');
('The query requires an index. You can create it here: https://console.firebase.google.com/v1/r/project/qura-dev-3/firestore/indexes?create_composite=Ckpwcm9qZWN0cy9xdXJhLWRldi0zL2RhdGFiYXNlcy8oZGVmYXVsdCkvY29sbGVjdGlvbkdyb3Vwcy9hY3Rpb25zL2luZGV4ZXMvXxABGhAKDGNvbnRlbnRfdHlwZRABGhAKDHN1Ym1pdHRlZF9hdBACGgwKCF9fbmFtZV9fEAI');
