import { keepPreviousData, useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { catchAndLogError } from '../../firebase/functions/sendErrorLog';
import { MAX_COMPLETIONS_PER_SEARCH, RESOURCE_API_URL } from '../constants';
import { standardAxiosOptions } from '../utils';
import { getAuthToken } from '@/services/firebase/functions/getCurrentUser';
import { mapAxiosError } from '@/utils/mapAxiosError';

export const getCompletionSuggestions = async (prefix: string, dataRole: string) => {
  if (prefix.trim().split(/\s+/).length < 3) {
    return { completions: [] };
  }

  const token = await getAuthToken();
  const response = await axios
    .post<{ completions: { suggestion: string }[] }>(
      `${RESOURCE_API_URL}/database/completion/suggestion`,
      {
        prefix,
        limit: MAX_COMPLETIONS_PER_SEARCH,
        data_role: dataRole,
      },
      {
        responseType: 'json',
        ...standardAxiosOptions(token),
      },
    )
    .catch((error: AxiosError) => {
      throw mapAxiosError(error);
    });

  return response.data;
};

export const useCompletionSuggestions = (
  prefix: string,
  dataRole: string | null,
  enabled: boolean,
) => {
  return useQuery({
    queryKey: ['completion_suggestions', prefix, dataRole],
    queryFn: () => catchAndLogError(() => getCompletionSuggestions(prefix, dataRole ?? '')),
    placeholderData: keepPreviousData,
    enabled: enabled && dataRole !== null,
  });
};
