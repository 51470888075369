import { ChangeEvent, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { usePostHog } from 'posthog-js/react';
import { useParams } from 'react-router-dom';
import { PageNumber } from './Virtualizer';
import Tag from '../Tag/Tag';
import { Icons } from '@/assets';
import { getAuthToken } from '@/services/firebase/functions/getCurrentUser';
import { DocumentProperties } from '@/types/api';
import devlog from '@/utils/devlog';
import { POSTHOG_EVENT } from '@/services/posthog/events';

type DebounceInputProps = {
  pageCount: number;
  setDebouncedValue: (value: PageNumber) => void;
  debouncedValue: number;
};
const DebounceInput = ({ pageCount, setDebouncedValue, debouncedValue }: DebounceInputProps) => {
  const [inputValue, setInputValue] = useState(1);
  const [debounce, setDebounce] = useState(true);

  useEffect(() => {
    setInputValue(debouncedValue + 1);
    setDebounce(false);
  }, [debouncedValue]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const int = parseInt(event.target.value);
    setDebounce(true);
    return setInputValue(int);
  };

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (debounce) {
      timeoutId = setTimeout(() => {
        if (isNaN(inputValue)) return { number: 0, id: Math.random(), offset: 5 };
        if (inputValue < 0) return setDebouncedValue({ number: 0, id: Math.random(), offset: 5 });
        if (inputValue > (pageCount ?? 0))
          return setDebouncedValue({
            number: pageCount - 1,
            id: Math.random(),
            offset: 5,
          });
        setDebouncedValue({ number: inputValue - 1, id: Math.random(), offset: 5 });
      }, 500);
    }
    return () => clearTimeout(timeoutId);
  }, [debounce, inputValue, pageCount, setDebouncedValue]);

  return (
    <input
      type="number"
      className="w-7 text-xs rounded text-center [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none focus:outline-none ring-1 ring-qura-neutral-silver hover:ring-qura-neutral-jet focus:ring-qura-neutral-balanced mr-1 "
      min={1}
      max={pageCount}
      value={inputValue}
      onChange={handleInputChange}
      data-np-intersection-state="hidden"
      autoComplete="off"
    />
  );
};

type ZoomInputProps = { scale: number; setScale: (value: number) => void };
const ZoomInput = ({ scale, setScale }: ZoomInputProps) => {
  const [focus, setFocus] = useState(false);

  return (
    <div
      className={`flex h-7 rounded-small border border-qura-neutral-silver ${focus ? ' bg-white' : 'bg-qura-neutral-ghost'}`}>
      <label
        className="flex justify-center items-center rounded-l-small text-qura-neutral-jet text-xs w-7"
        htmlFor="scalepecentage">
        <Icons.Search className="size-3.5" />
      </label>
      <div className="w-[1px] bg-qura-neutral-silver" />
      <input
        className="w-max caret-transparent text-qura-neutral-jet bg-transparent text-xs outline-none cursor-default text-right px-1"
        id="scalepercentage"
        type="number"
        value={scale}
        min={50}
        max={300}
        step={10}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        onChange={(event) => setScale(parseFloat(event.target.value) || 100)}
        onKeyDown={(e) => e.preventDefault()}
        data-np-intersection-state="hidden"
        autoComplete="off"
      />
      <div className="w-[1px] bg-qura-neutral-silver" />
      <label
        htmlFor="scalepercentage"
        className="flex justify-center items-center rounded-r-small text-qura-neutral-jet text-xs  w-7">
        %
      </label>
    </div>
  );
};

type DownloadButtonProps = {
  withHighlightingUrl?: string;
  withoutHighlightingUrl: string;
  title: string;
};
const DownloadButton = (props: DownloadButtonProps) => {
  const { t } = useTranslation();
  const { withHighlightingUrl, withoutHighlightingUrl, title } = props;
  const [showDropdown, setShowDropdown] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const downloadButtonRef = useRef<HTMLButtonElement | null>(null);
  const posthog = usePostHog();

  const { searchId = '' } = useParams();

  const onDownloadWithHighlighting = () => {
    if (!withHighlightingUrl) throw new Error('withHighlightingUrl is required');
    posthog.capture(POSTHOG_EVENT.DOWNLOAD_PDF_WITH_HIGHLIGHTING, {
      title,
      searchId,
      url: withHighlightingUrl,
    });
    setDownloading(true);
    downloadPDF(withHighlightingUrl)
      .then(() => {
        setDownloading(false);
      })
      .catch((err) => {
        setDownloading(false);
        toast.error(t('searchDocumentPage.downloadError'));
        devlog(err);
      });
    setShowDropdown(false);
  };

  const onDownloadWithoutHighlighting = () => {
    setDownloading(true);
    posthog.capture(POSTHOG_EVENT.DOWNLOAD_PDF_WITHOUT_HIGHLIGHTING, {
      title,
      searchId,
      url: withoutHighlightingUrl,
    });
    downloadPDF(withoutHighlightingUrl)
      .then(() => {
        setDownloading(false);
      })
      .catch((err) => {
        setDownloading(false);
        toast.error(t('searchDocumentPage.downloadError'));
        devlog(err);
      });
    setShowDropdown(false);
  };

  const downloadPDF = async (url: string) => {
    const authToken = await getAuthToken();

    const response = await axios({
      url: url,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      responseType: 'blob',
    });

    const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', `${title.replace(/[^a-zåäö0-9]+/gi, '-')}.pdf`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadClick = () => {
    if (downloading) return;
    if (withHighlightingUrl) {
      setShowDropdown(!showDropdown);
    } else {
      setShowDropdown(false);
      onDownloadWithoutHighlighting();
    }
  };

  const hideDropdown = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node) &&
      downloadButtonRef.current &&
      !downloadButtonRef.current.contains(event.target as Node)
    ) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    if (showDropdown) {
      window.addEventListener('click', hideDropdown);
    }

    return () => window.removeEventListener('click', hideDropdown);
  }, [showDropdown]);

  return (
    <div>
      <div
        ref={dropdownRef}
        className={`absolute bg-white rounded-lg flex flex-col justify-start shadow-qura px-2 py-2 z-10 top-10 text-xs ${showDropdown ? 'visible' : 'hidden'}`}>
        <button
          className="rounded-[6px] p-2 cursor-pointer hover:bg-qura-neutral-ghost text-left"
          onClick={onDownloadWithHighlighting}>
          <p>{t('searchDocumentPage.downloadWithHighlights')}</p>
        </button>
        <button
          className=" rounded-[6px] p-2 cursor-pointer hover:bg-qura-neutral-ghost text-left"
          onClick={onDownloadWithoutHighlighting}>
          <p>{t('searchDocumentPage.downloadWithoutHighlights')}</p>
        </button>
      </div>
      <button
        ref={downloadButtonRef}
        className={`border border-qura-neutral-silver text-qura-neutral-jet hover:bg-white bg-qura-neutral-ghost text-xs px-2 h-7 rounded ${downloading ? 'cursor-not-allowed animate-pulse' : 'cursor-pointer'}`}
        onClick={downloadClick}>
        {t('searchDocumentPage.downloadPdf')}
      </button>
    </div>
  );
};

type PDFReaderTopBarProps = {
  legalId?: string;
  title: string;
  scale: number;
  setScale: (value: number) => void;
  currentPage: number;
  setScrollToPage: (value: PageNumber) => void;
  documentProperties?: DocumentProperties;
  withHighlightingUrl?: string;
  withoutHighlightingUrl: string;
};
export const PDFReaderTopBar = ({
  legalId,
  title,
  scale,
  setScale,
  currentPage,
  setScrollToPage,
  documentProperties,
  withHighlightingUrl,
  withoutHighlightingUrl,
}: PDFReaderTopBarProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex justify-between items-center border-b px-4 py-3">
      <Tag className="border border-qura-neutral-light">
        <Icons.Book className="mr-2 mb-[1px]" />
        <p className="font-medium">{legalId || '-'}</p>
      </Tag>
      {documentProperties ? (
        <>
          <div className="flex">
            <DebounceInput
              debouncedValue={currentPage}
              setDebouncedValue={setScrollToPage}
              pageCount={documentProperties.pageCount}
            />
            <p className="text-xs"> / {documentProperties.pageCount}</p>
          </div>
          <div className="flex items-center relative gap-4">
            <DownloadButton
              withHighlightingUrl={withHighlightingUrl}
              withoutHighlightingUrl={withoutHighlightingUrl}
              title={title}
            />
            <ZoomInput scale={scale} setScale={setScale} />
          </div>
        </>
      ) : (
        <p className="text-sm animate-pulse">{t('common.loading')}</p>
      )}
    </div>
  );
};
