import { useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { catchAndLogError } from '../../firebase/functions/sendErrorLog';
import { RESOURCE_API_URL } from '../constants';
import { standardAxiosOptions } from '../utils';
import { availableDateRangeSchema } from '../apiValidation';
import { getAuthToken } from '@/services/firebase/functions/getCurrentUser';
import { mapAxiosError } from '@/utils/mapAxiosError';

export async function getAvailableDateRangeRequest(dataRole?: string, tagIds: string[] = []) {
  if (!dataRole) {
    return null;
  }
  const token = await getAuthToken();
  const response = await axios
    .post(
      `${RESOURCE_API_URL}/database/filters/dates`,
      {
        data_role: dataRole,
        tagIds,
      },
      standardAxiosOptions(token),
    )
    .catch((error: AxiosError) => {
      throw mapAxiosError(error);
    });

  try {
    return availableDateRangeSchema.parse({
      after_date: response.data.start_date,
      before_date: response.data.end_date,
    });
  } catch (_) {
    return null;
  }
}

export const useAvailableDateRange = (dataRole?: string, tagIds: string[] = []) => {
  return useQuery({
    queryKey: ['filter_dates', dataRole, ...tagIds],
    queryFn: () => catchAndLogError(() => getAvailableDateRangeRequest(dataRole, tagIds)),
  });
};
