import posthog from 'posthog-js';
import { addDoc, collection } from 'firebase/firestore';
import { getLogMetadata } from '@/utils/getLogMetadata';
import { catchAppError } from '@/utils/createAppError';
import devlog from '@/utils/devlog';
import { db } from '@/services/firebase/firebaseConfig';
import { getAuth } from 'firebase/auth';
import { POSTHOG_EVENT } from '@/services/posthog/events';

export async function sendErrorLog(error: unknown) {
  const errorData = JSON.stringify(error, null, 2).replace(/\n/g, '\\n');
  const appError = catchAppError(error);

  if (appError.type === 'unimportant') {
    return;
  }

  const authUser = getAuth().currentUser;

  posthog.capture(POSTHOG_EVENT.APP_ERROR, {
    type: appError.type,
    message: appError.message,
    error: errorData,
  });

  devlog('LOGGING ERROR', JSON.stringify(appError, null, 2).replace(/\n/g, '\\n'));

  try {
    await addDoc(collection(db, 'errorLog'), {
      message: appError.message,
      type: appError.type,
      error: errorData,
      data: getLogMetadata(),
      timestamp: new Date(),
      userId: authUser?.uid ?? null,
    });
  } catch (error) {
    console.error('Failed to upload error log to Firebase', error);
  }
}

export async function catchAndLogError<T>(func: () => Promise<T>) {
  try {
    return await func();
  } catch (error) {
    sendErrorLog(error);
    throw error;
  }
}
