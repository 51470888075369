import posthog from 'posthog-js';
import { useSearchStore } from '../searchStore';
import { convertBackToSnakeCaseRecursive } from '@/utils/snakeToCamelCase';
import { POSTHOG_EVENT } from '@/services/posthog/events';

export const sendSearchParams = (searchId: string) => {
  const ws = useSearchStore.getState().searches[searchId]?.ws;

  if (!ws) {
    throw new Error('WebSocket not initialized');
  }

  const currentParams = useSearchStore.getState().currentParams;
  const params = {
    ...currentParams,
  };

  const payload = convertBackToSnakeCaseRecursive({
    content_type: 'search',
    content: params,
  });

  posthog.capture(POSTHOG_EVENT.SEARCH_START, {
    params: params,
    searchId,
  });

  ws.send(JSON.stringify(payload));
};
