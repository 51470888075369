import { useQuery } from '@tanstack/react-query';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { sendErrorLog } from '../../firebase/functions/sendErrorLog';
import { RESOURCE_API_URL } from '../constants';
import { standardAxiosOptions } from '../utils';
import { getAuthToken } from '@/services/firebase/functions/getCurrentUser';
import { mapAxiosError } from '@/utils/mapAxiosError';

export const getExcerpt = async (searchId: string, excerptId: string): Promise<Blob> => {
  const token = await getAuthToken();
  const url = `${RESOURCE_API_URL}/search/${searchId}/excerpt/${excerptId}`;
  const options: AxiosRequestConfig = {
    ...standardAxiosOptions(token),
    responseType: 'blob',
    timeout: 10_000,
  };
  const response = await axios.get(url, options).catch((error: AxiosError) => {
    throw mapAxiosError(error);
  });
  return response.data;
};
export const useExcerpt = (searchId: string, excerptId: string, enabled: boolean) => {
  const retryAllowedTime = 120_000;
  const startTime = Date.now();

  return useQuery({
    queryKey: ['excerpt', searchId, excerptId],
    queryFn: async () => {
      try {
        const excerpt = await getExcerpt(searchId, excerptId);
        return excerpt;
      } catch (error) {
        const timeSinceFirstAttempt = Date.now() - startTime;
        const shouldLogError = timeSinceFirstAttempt >= retryAllowedTime;
        if (shouldLogError) {
          sendErrorLog(error);
        }
        throw error;
      }
    },
    retry: () => {
      const timeSinceFirstAttempt = Date.now() - startTime;
      return timeSinceFirstAttempt < retryAllowedTime;
    },
    throwOnError: false,
    retryDelay: 500,
    enabled,
  });
};
