import { signOut } from 'firebase/auth';
import { auth } from '../firebaseConfig';
import { createAppError } from '@/utils/createAppError';
import posthog from 'posthog-js';
import { POSTHOG_EVENT } from '@/services/posthog/events';

export async function logout() {
  try {
    posthog.capture(POSTHOG_EVENT.LOGOUT);
    await signOut(auth);
  } catch (error: any) {
    const message = error.message;
    throw createAppError(
      'unknown',
      'Failed to log out with Firebase. Firebase Error Message: ' + message,
    );
  }
}
