import { usePostHog } from 'posthog-js/react';
import { FC, MouseEventHandler, ReactNode, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { addTag, removeTags } from '../../state/actions/searchParams';
import { useTagCount } from '../../state/hooks/useTagCount';
import { useSearchStore } from '../../state/searchStore';
import { TagNode } from '@/types/api';
import { Icons } from '@/assets';
import { POSTHOG_EVENT } from '@/services/posthog/events';

type Props = {
  tag: TagNode;
  parentTag: TagNode | null;
  renderSubTag?: (parentOption: TagNode, parentTag: TagNode) => ReactNode;
};

const TagButton: FC<Props> = ({ tag, parentTag, renderSubTag }) => {
  const posthog = usePostHog();
  const recursiveTagIdsFromHere = useMemo(() => {
    const tagIds = new Set<string>();
    const tagQueue = [tag];

    while (tagQueue.length > 0) {
      const tag = tagQueue.pop()!;
      if (tagIds.has(tag.tagId)) continue;
      tagIds.add(tag.tagId);
      tagQueue.push(...tag.subNodes);
    }

    return tagIds;
  }, [tag]);

  const [hideSuboptionsWhenSelected, setHideSuboptionsWhenSelected] = useState(false);
  const [hideSuboptionsWhenUnselected, setHideSuboptionsWhenUnselected] = useState(true);
  const isSelected = useSearchStore((state) =>
    state.currentParams.filter?.tagIds.some((tagId) => recursiveTagIdsFromHere.has(tagId)),
  );
  const hideSuboptions = isSelected ? hideSuboptionsWhenSelected : hideSuboptionsWhenUnselected;

  const { searchId = '' } = useParams();
  const tagCountMap = useTagCount(searchId);
  const tagCount = tagCountMap?.[tag.tagId] || 0;

  const sortedSubNodes = useMemo(
    () => tag.subNodes.sort((a, b) => a.weight - b.weight),
    [tag.subNodes],
  );

  const selectClick = () => {
    if (isSelected) {
      removeTags(recursiveTagIdsFromHere);
      posthog.capture(POSTHOG_EVENT.FILTER_TAG_DESELECT, { tagId: tag.tagId, searchId });
      if (parentTag) addTag(parentTag);
    } else {
      posthog.capture(POSTHOG_EVENT.FILTER_TAG_SELECT, { tagId: tag.tagId, searchId });
      addTag(tag);
      if (parentTag) removeTags([parentTag.tagId]);
    }
  };

  const toggleHidden: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    if (isSelected) {
      setHideSuboptionsWhenSelected((hide) => !hide);
    } else {
      setHideSuboptionsWhenUnselected((hide) => !hide);
    }
  };

  return (
    <>
      <div
        className={`flex flex-1 gap-2 first-letter items-center justify-left cursor-pointer`}
        onClick={selectClick}>
        <div
          className={`rounded-md px-2 py-[5px] flex items-center flex-1 cursor-pointer hover:bg-qura-neutral-ghost ${isSelected ? 'bg-qura-neutral-ghost' : ''}`}>
          <div className="w-2 h-2 rounded-full border border-qura-neutral-jet flex justify-center items-center">
            {isSelected && <div className="w-1 h-1 rounded-full bg-qura-neutral-jet" />}
          </div>
          <p className={`flex-1 pl-2 text-xs  ${isSelected ? '' : 'text-qura-neutral-jet mr-1'}`}>
            {tag.label}
          </p>
          {tag.subNodes.length > 0 && renderSubTag && (
            <button className="text-right hover:underline" onClick={toggleHidden}>
              <Icons.Arrow className={`w-2.5 ${hideSuboptions ? ' ' : 'rotate-180'}`} />
            </button>
          )}
        </div>
        <div
          className={`flex text-xs justify-center items-center w-[25px] py-[5px] rounded-md text-qura-neutral-balanced bg-qura-neutral-ghost ${tagCount === 0 ? 'opacity-0' : ''}`}>
          {tagCount}
        </div>
      </div>
      {tag.subNodes.length > 0 && renderSubTag && !hideSuboptions && (
        <div className="flex">
          <div className="w-[1px] -mt-1.5 mb-3 ml-[11px] mr-[3px] bg-qura-neutral-silver" />
          <div className="flex flex-col flex-1 space-y-1.5">
            {sortedSubNodes.map((subtag) => renderSubTag(subtag, tag))}
          </div>
        </div>
      )}
    </>
  );
};

export default TagButton;
