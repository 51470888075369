import { useLocation } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { LoadingCover } from '../components/LoadingCover';
import { useEmailVerify } from '../hooks/useEmailVerify';

interface RedirectIfAuthenticatedRouteProps {
  children: JSX.Element;
}

export const VerifyEmail = ({ children }: RedirectIfAuthenticatedRouteProps) => {
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const confirmationCode = searchParams.get('confirmation');

  const { isLoading, isError } = useEmailVerify(confirmationCode);
  const auth = getAuth();

  if (!confirmationCode) {
    return children;
  }

  // wait for the user to be logged in before hiding the loading screen
  const isUserLoading = (isLoading || !auth.currentUser) && !isError;

  return <LoadingCover isLoading={isUserLoading}>{children}</LoadingCover>;
};
