import { doc, DocumentReference, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { z } from 'zod';
import { convertSnakeToCamelCaseObject } from '@/utils/snakeToCamelCase';

const dataRoleSchema = z
  .object({
    role_name: z.string(),
    label: z.string(),
    example_prompts: z.array(z.string()),
    weight: z.number(),
  })
  .transform(convertSnakeToCamelCaseObject);

const storedUserSchema = z.object({
  username: z.string(),
  companyId: z.string(),
  language: z.enum(['en', 'sv']),
  code: z.string().nullable().default(null),
  emailVerified: z.boolean(),
  dataRoles: z.array(dataRoleSchema),
});

export type DataRole = z.infer<typeof dataRoleSchema>;
export type StoredUser = z.infer<typeof storedUserSchema>;

export async function getStoredUserRequest(uid: string) {
  const userDocRef = doc(db, 'users', uid);
  const userDocSnapshot = await getDoc(userDocRef);

  if (!userDocSnapshot.exists()) {
    throw new Error('User not found');
  }
  const userDocData = userDocSnapshot.data();
  const populatedData = {
    ...userDocData,
    dataRoles: await Promise.all(
      userDocData.dataRoles.map(async (ref: DocumentReference) => (await getDoc(ref)).data()),
    ),
  };
  const storedUser = storedUserSchema.parse(populatedData);

  return storedUser;
}
