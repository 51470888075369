import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { useUserData } from '../firebase/context/useUserData';

export const useSentryTags = () => {
  const { data } = useUserData();

  useEffect(() => {
    Sentry.setTag('user_uid', data?.authUser.uid);
    Sentry.setTag('user_email', data?.authUser.email);
    Sentry.setTag('user_name', data?.storedUser.username);
    Sentry.setTag('user_company_id', data?.storedUser.companyId);
    Sentry.setTag('user_company_name', data?.company.companyName);
    Sentry.setTag('user_language', data?.storedUser.language);
  }, [data]);
};
