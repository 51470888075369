import { t } from 'i18next';
import { FC } from 'react';
import { AppErrorType } from '@/utils/createAppError';

export interface AppError {
  type: AppErrorType;
  message: string;
}

type Props = {
  className?: string;
  error: AppError | null;
};

const getErrorText = (error: AppError) => {
  switch (error.type) {
    case 'unimportant':
      return '';
    case 'invalid-credentials':
      return t('errors.invalidCredentials');
    case 'network':
      return t('errors.network');
    case 'invalid-signup-token':
      return t('errors.invalidSignupToken');
    case 'message':
      return error.message;
    case 'email-not-confirmed':
      return t('errors.emailNotConfirmed');
    case 'email-already-used':
      return t('errors.emailAlreadyUsed');
    case 'no-results':
      return t('errors.noResults');
    case 'load-resources-failed':
      return 'Our servers are currently down.\nPlease try again later.';
    case 'email-verify-failed':
      return 'Email verification failed.';
    case 'email-verify-success':
      return 'Email verification succeeded.';
    case 'unknown':
      return t('errors.unknown');
    case 'websocket-error':
      return 'WebSocket error occurred.';
    case 'ws-validation-error':
      return 'WebSocket validation error occurred.';
    case 'identical-request':
      return '';
    case 'unauthenticated':
      return t('errors.unauthenticated');
  }
};

const ErrorMessage: FC<Props> = ({ error, className }) => {
  if (
    error === null ||
    error.type === 'unimportant' ||
    error.type === 'email-not-confirmed' ||
    error.type === 'identical-request'
  ) {
    return null;
  }

  return (
    <>
      {getErrorText(error)
        .split('\n')
        .map((m) => (
          <p key={m} className={`text-[12px] ${className}`}>
            {m}
          </p>
        ))}
    </>
  );
};

export default ErrorMessage;
