import { sendErrorLog } from '../../firebase/functions/sendErrorLog';
import { getAuthToken } from '../../firebase/functions/getCurrentUser';
import { webSocketOutputSchema } from '../apiValidation';
import { SEARCH_API_URL } from '../constants';
import { SearchResult } from '@/types/api';
import { AppError } from '@/common/ErrorMessage/ErrorMessage';
import { catchAppError, createAppError } from '@/utils/createAppError';
import devlog from '@/utils/devlog';

interface InitWebSocketParams {
  onSearchId: (searchId: string, ws: WebSocket) => void;
  onResult: (searchId: string, result: SearchResult) => void;
  onError: (err: AppError) => void;
  onShutdown: (searchId: string | null) => void;
}

/**
 * Returns a promise that resolves when we receive the search id from the server
 * @param params
 * @returns
 */
export const initSearchWebSocket = async (params: InitWebSocketParams) => {
  const { onSearchId, onResult, onError, onShutdown } = params;
  const token = await getAuthToken();
  const socketUrl = `${SEARCH_API_URL}/connect?token=${token}`;
  const ws = new WebSocket(socketUrl);
  devlog('WS OPENED');

  let searchId: string | null = null;

  ws.onclose = () => {
    devlog('WS CLOSED');
    onShutdown(searchId);
  };

  return new Promise<{ ws: WebSocket; searchId: string }>((resolve, reject) => {
    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      const { data: output, error } = webSocketOutputSchema.safeParse(data);

      if (!output) {
        devlog('WS MESSAGE PARSE ERROR', error);
        return;
      }

      switch (output.contentType) {
        case 'search_id':
          searchId = output.searchId;
          onSearchId(searchId, ws);
          resolve({ ws, searchId });
          break;
        case 'result':
          if (!searchId) {
            onError(createAppError('unknown', 'searchId is not set'));
            return;
          }
          onResult(searchId, output.content);
          break;
        case 'shutdown':
          onShutdown(searchId);
          ws.close();
          break;
        case 'error':
          if (output.content.error.type !== 'timeout') {
            onError(createAppError('unknown', JSON.stringify(output.content)));
          }
          break;
      }
    };

    ws.onerror = (event) => {
      ws.close();
      sendErrorLog(event);
      onError(catchAppError(event));
      reject(catchAppError(event));
    };
  });
};
