import { keepPreviousData, useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { catchAndLogError } from '../../firebase/functions/sendErrorLog';
import { MAX_COMPLETIONS_PER_SEARCH, RESOURCE_API_URL } from '../constants';
import { standardAxiosOptions } from '../utils';
import { getAuthToken } from '@/services/firebase/functions/getCurrentUser';
import { mapAxiosError } from '@/utils/mapAxiosError';

export const getDocumentSuggestions = async (prefix: string, dataRole: string) => {
  if (prefix.trim().length === 0) {
    return { completions: [] };
  }

  const token = await getAuthToken();
  const response = await axios
    .post<{
      completions: { legal_id: string; title: string; document_id: string }[];
    }>(
      `${RESOURCE_API_URL}/database/completion/legal`,
      {
        prefix,
        limit: MAX_COMPLETIONS_PER_SEARCH,
        data_role: dataRole,
      },
      {
        responseType: 'json',
        ...standardAxiosOptions(token),
      },
    )
    .catch((error: AxiosError) => {
      throw mapAxiosError(error);
    });

  return response.data;
};

export const useDocumentSuggestions = (
  prefix: string,
  dataRole: string | null,
  enabled: boolean,
) => {
  return useQuery({
    queryKey: ['document_suggestions', prefix, dataRole],
    queryFn: () => catchAndLogError(() => getDocumentSuggestions(prefix, dataRole ?? '')),
    placeholderData: keepPreviousData,
    enabled: enabled && dataRole !== null,
  });
};
