import { useEffect } from 'react';
import { useUserData } from './services/firebase/context/useUserData';
import i18n from './services/i18next/i18nextConfig';

export const SetUserLanguage = () => {
  const { data } = useUserData();

  useEffect(() => {
    if (data?.storedUser.language) {
      i18n.changeLanguage(data?.storedUser.language);
    }
  }, [data?.storedUser.language]);

  return null;
};
