import { Navigate, Route, Routes } from 'react-router-dom';
import { AuthRedirect } from './features/Auth/guards/AuthRedirect';
import { VerifyEmail } from './features/Auth/guards/VerifyEmail';
import { EmailConfirmationPage } from './features/Auth/pages/EmailConfirmationPage/EmailConfirmationPage';
import { ForgotPasswordPage } from './features/Auth/pages/ForgotPasswordPage/ForgotPasswordPage';
import { LoginPage } from './features/Auth/pages/LoginPage/LoginPage';
import { PasswordResetPage } from './features/Auth/pages/PasswordResetPage/PasswordResetPage';
import { SignupPage } from './features/Auth/pages/SignupPage/SignupPage';
import SearchDocumentPage from './features/Search/pages/SearchDocumentPage/SearchDocumentPage';
import { SearchPromptPage } from './features/Search/pages/SearchPromptPage/SearchPromptPage';
import { SearchResultPage } from './features/Search/pages/SearchResultPage/SearchResultPage';
import { useSearchDepth } from './services/posthog/useSearchDepth';
import { useSentryTags } from './services/sentry/useSentryTags';

export const AppRoutes = () => {
  useSentryTags();
  useSearchDepth();
  return (
    <Routes>
      <Route
        index
        element={
          <AuthRedirect onLoggedOut="/auth" onEmailNotVerified="/auth/confirm-email">
            <SearchPromptPage />
          </AuthRedirect>
        }
      />
      <Route
        path="/search/:searchId"
        element={
          <AuthRedirect onLoggedOut="/auth" onEmailNotVerified="/auth/confirm-email">
            <SearchResultPage />
          </AuthRedirect>
        }
      />
      <Route
        path="/search/:searchId/:documentId"
        element={
          <AuthRedirect onLoggedOut="/auth" onEmailNotVerified="/auth/confirm-email">
            <SearchDocumentPage />
          </AuthRedirect>
        }
      />
      <Route
        path="/document/:documentId"
        element={
          <AuthRedirect onLoggedOut="/auth" onEmailNotVerified="/auth/confirm-email">
            <SearchDocumentPage />
          </AuthRedirect>
        }
      />
      <Route
        path="/auth"
        element={
          <AuthRedirect onLoggedIn="/">
            <VerifyEmail>
              <LoginPage />
            </VerifyEmail>
          </AuthRedirect>
        }
      />
      <Route
        path="/auth/signup"
        element={
          <AuthRedirect onLoggedIn="/">
            <SignupPage />
          </AuthRedirect>
        }
      />
      <Route path="/auth/forgot-password" element={<ForgotPasswordPage />} />
      <Route path="/auth/confirm-email" element={<EmailConfirmationPage />} />
      <Route path="/auth/reset-password" element={<PasswordResetPage />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};
