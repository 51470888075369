export const HelpButton = () => {
  return (
    <div className="fixed z-10 left-8 bottom-8 pointer-events-auto flex items-center flex-col">
      <a href="https://www.qura.law/quick-guide" target="_blank" rel="noopener noreferrer">
        <button
          className="shadow-qura flex items-center justify-center h-14 w-14 bg-white bg-opacity-90 rounded-full"
          aria-label="Help">
          <span className="text-black text-lg font-semibold">?</span>
        </button>
      </a>
    </div>
  );
};
