import { useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { catchAndLogError } from '../../firebase/functions/sendErrorLog';
import { RESOURCE_API_URL } from '../constants';
import { standardAxiosOptions } from '../utils';
import { documentPropertiesSchema } from '../apiValidation';
import { getAuthToken } from '@/services/firebase/functions/getCurrentUser';
import { mapAxiosError } from '@/utils/mapAxiosError';

export async function getDocumentProperties(documentId: string) {
  const token = await getAuthToken();
  const response = await axios
    .get(
      `${RESOURCE_API_URL}/database/document/${documentId}/properties`,
      standardAxiosOptions(token),
    )
    .catch((error: AxiosError) => {
      throw mapAxiosError(error);
    });
  return documentPropertiesSchema.parse(response.data);
}

export const useDocumentProperties = (documentId: string) => {
  return useQuery({
    queryKey: ['filter_dates', documentId],
    queryFn: () => catchAndLogError(() => getDocumentProperties(documentId)),
    retry: 1,
  });
};
