import { useEffect, useMemo, useRef } from 'react';
import { useSearchStore } from '../../state/searchStore';
import { setActiveDataRole } from '../../state/actions/searchParams';
import { useUserData } from '@/services/firebase/context/useUserData';

export const DataRoleToggle = () => {
  const { data: userData } = useUserData();

  const sortedDataRoles = useMemo(() => {
    if (userData) {
      return userData.storedUser.dataRoles.sort((a, b) => a.weight - b.weight);
    }
    return [];
  }, [userData]);

  const selectedDataRole = useSearchStore((s) => s.currentParams.dataRole);

  const defaultTo = useMemo(() => {
    if (selectedDataRole === null && sortedDataRoles.length > 0) {
      return sortedDataRoles[0].roleName;
    }
    return null;
  }, [selectedDataRole, sortedDataRoles]);

  const visible = sortedDataRoles.length > 1;
  const wasVisibleRef = useRef(visible);

  useEffect(() => {
    if (defaultTo) {
      setActiveDataRole(defaultTo);
    }
  }, [defaultTo]);

  const selectDataRole = (roleId: string) => {
    setActiveDataRole(roleId);
  };

  return (
    <div className="flex gap-2 h-6">
      {visible &&
        sortedDataRoles.map((role) => {
          return (
            <div
              className={`flex ${wasVisibleRef.current ? '' : 'animate-fade-in'}`}
              key={role.roleName}>
              <p
                className={`flex items-center text-tiny font-medium ${
                  role.roleName === selectedDataRole
                    ? 'bg-black rounded-full text-white'
                    : 'cursor-pointer border rounded-full'
                } px-3`}
                onClick={() => selectDataRole(role.roleName)}>
                {role.label}
              </p>
            </div>
          );
        })}
    </div>
  );
};
