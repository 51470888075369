import { collection, getDocs, limit, orderBy, query, where } from 'firebase/firestore';
import { useQuery } from '@tanstack/react-query';
import { getCurrentUser } from './getCurrentUser';
import { db } from '../firebaseConfig';
import { searchParamsSchema } from '@/services/api/apiValidation';
import { SearchParams } from '@/types/api';
import { catchAndLogError } from './sendErrorLog';

const FETCH_SEARCHES_LIMIT = 25;

export async function getRecentSearches(maxSearches: number) {
  const user = getCurrentUser();

  if (!user) {
    throw new Error('No user found');
  }

  const searchesRef = collection(db, 'searches');
  const q = query(
    searchesRef,
    where('user_id', '==', user.uid),
    orderBy('created_at', 'desc'),
    limit(FETCH_SEARCHES_LIMIT),
  );
  const searchesSnapshot = await getDocs(q);

  if (searchesSnapshot.empty) {
    return [];
  }

  const queries = new Set<string>();
  const seraches: { params: SearchParams; searchId: string }[] = [];

  await Promise.all(
    searchesSnapshot.docs.map(async (searchDoc) => {
      const actionsRef = collection(searchDoc.ref, 'actions');
      const lastActionQuery = query(
        actionsRef,
        where('content_type', '==', 'search'),
        orderBy('submitted_at', 'desc'),
        limit(1),
      );
      const lastActionSnapshot = await getDocs(lastActionQuery);

      if (!lastActionSnapshot.empty) {
        const action = lastActionSnapshot.docs[0].data();

        const params = searchParamsSchema.parse(action['content']);
        if (!queries.has(params.query)) {
          queries.add(params.query);
          seraches.push({ params, searchId: searchDoc.data()['search_id'] });
        }
      } else {
        throw new Error('No actions found for search');
      }
    }),
  );

  return seraches.slice(0, maxSearches);
}

export const useRecentSearches = (maxSearches: number) => {
  return useQuery({
    queryKey: ['recent_searches', maxSearches],
    queryFn: () => catchAndLogError(() => getRecentSearches(maxSearches)),
  });
};
