import { httpsCallable } from 'firebase/functions';
import { useQuery } from '@tanstack/react-query';
import { functions } from '../firebaseConfig';
import { catchAndLogError } from '@/services/firebase/functions/sendErrorLog';

const _getCompanyFromSignupToken = httpsCallable(functions, 'getCompanyFromSignupToken');

export async function getCompanyFromSignupToken(token: string | null): Promise<string> {
  try {
    const result = await _getCompanyFromSignupToken({ token });

    if (!result.data) {
      throw new Error('No data returned from the function.');
    }

    if (typeof result.data !== 'string') {
      throw new Error('The data returned from the function is not a string.');
    }

    return result.data;
  } catch (error: any) {
    throw new Error(`An error occurred while fetching the company. ${error.message}`);
  }
}

export const useCompanyFromSignupToken = (token: string | null) => {
  return useQuery({
    queryKey: ['company', token],
    queryFn: () => catchAndLogError(() => getCompanyFromSignupToken(token)),
  });
};
