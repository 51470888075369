import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import 'core-js';
import { I18nextProvider } from 'react-i18next';
import { pdfjs } from 'react-pdf';
import { BrowserRouter } from 'react-router-dom';
import { AppRoutes } from './AppRoutes';
import useAppropriateFavicon from './hooks/useAppropriateFavicon';
import { UserDataProvider } from './services/firebase/context/UserDataContext';
import i18n from './services/i18next/i18nextConfig';
import { queryClient } from './services/react-query/queryClient';
import { SetUserLanguage } from './SetUserLanguage';
import { IdentifyPosthog } from './IdentifyPosthog';

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.mjs';

function App() {
  useAppropriateFavicon();
  return (
    <UserDataProvider>
      <QueryClientProvider client={queryClient}>
        <I18nextProvider i18n={i18n}>
          <BrowserRouter>
            <SetUserLanguage />
            <IdentifyPosthog />
            <AppRoutes />
          </BrowserRouter>
        </I18nextProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </UserDataProvider>
  );
}

export default App;
