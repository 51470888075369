/* eslint-disable react-hooks/exhaustive-deps */
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

import { useCallback, useEffect, useMemo, useRef } from 'react';
import { Document, Page as ReactPDFPage } from 'react-pdf';
import { DocumentProperties } from 'src/types/api';

import { useVirtualizer } from '@tanstack/react-virtual';
import LoadingGradient from '../LoadingGradient/LoadingGradient';
import { useAuthToken } from '@/services/firebase/functions/getCurrentUser';
import { Icons } from '@/assets';
import devlog from '@/utils/devlog';

export type PageNumber = {
  number: number;
  id: number;
  offset: number;
};

export const PDFPageLoading = ({ width, height }: { width: number; height: number }) => (
  <div
    style={{
      width: width,
      height: height,
    }}
    className="flex justify-center items-center">
    <div>
      <Icons.LogoText className=" h-4" />
    </div>
  </div>
);

const Page = ({
  index,
  width,
  height,
  scale,
}: {
  index: number;
  width: number;
  height: number;
  scale: number;
}) => {
  return (
    <ReactPDFPage
      loading={
        <LoadingGradient className=" rounded-none opacity-60" width={width} height={height} />
      }
      error={<PDFPageLoading width={width} height={height} />}
      pageIndex={index}
      width={width}
      scale={scale}
    />
  );
};

type VirtualPageListProps = {
  pageCount: number;
  pageMeta: DocumentProperties['pageProperties'];
  pageNumber: PageNumber;
  scale: number;
  setCurrentPage: (value: number) => void;
};
const VirtualPageList = ({
  pageCount,
  pageMeta,
  pageNumber,
  setCurrentPage,
  scale,
}: VirtualPageListProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const virtualizer = useVirtualizer({
    count: pageCount,
    getScrollElement: () => ref.current,
    estimateSize: (i: number) => (pageMeta[i]?.height || 0) * scale,
    overscan: 10,
    gap: 10,
  });
  const items = virtualizer.getVirtualItems();

  useEffect(() => {
    if (!virtualizer.range) return;
    const middleIndex = Math.floor((virtualizer.range.startIndex + virtualizer.range.endIndex) / 2);
    setCurrentPage(middleIndex);
  }, [setCurrentPage, virtualizer.range?.startIndex, virtualizer.range?.endIndex]);

  useEffect(() => {
    const offset = virtualizer.getOffsetForIndex(pageNumber.number, 'start');
    if (offset) virtualizer.scrollToOffset(offset[0] - 10 + pageNumber.offset);
  }, [pageNumber.id, pageNumber.number, virtualizer]);

  return (
    <div
      ref={ref}
      className={`h-full w-full overflow-y-auto overflow-x-auto flex justify-center [contain:strict]  bg-qura-neutral-ghost rounded-b-xl`}>
      <div style={{ height: virtualizer.getTotalSize() }} className={`relative w-full`}>
        {items.map((virtualItem) => {
          const pageHeight = pageMeta[virtualItem.index]?.height;
          const pageWidth = pageMeta[virtualItem.index]?.width;

          if (pageHeight && pageWidth) {
            return (
              <div
                key={virtualItem.key}
                data-index={virtualItem.index}
                ref={virtualizer.measureElement}
                className={`${
                  virtualItem.index % 2 ? 'ListItemOdd' : 'ListItemEven'
                } top-0 left-0 right-0 mr-auto ml-auto absolute`}
                style={{
                  width: pageWidth * scale,
                  height: pageHeight * scale,
                  transform: `translateY(${virtualItem.start}px)`,
                }}>
                <Page
                  index={virtualItem.index}
                  width={pageWidth}
                  height={pageHeight}
                  scale={scale}
                />
              </div>
            );
          }

          return null;
        })}
      </div>
    </div>
  );
};

interface VirtualizerProps {
  fileUrl: string;
  pageNumber: PageNumber;
  pageCount: number;
  pageMetadata: DocumentProperties['pageProperties'];
  setCurrentPage: (value: number) => void;
  onLoadSuccess?: () => void;
  scale: number;
}

const Virtualizer = (props: VirtualizerProps) => {
  const { fileUrl, pageNumber, pageCount, pageMetadata, scale, setCurrentPage, onLoadSuccess } =
    props;
  const { data: token } = useAuthToken();

  // We memoize parameters to prevent unnecessary re-renders
  // We can end up in a re-render loop otherwise due to how the state is updated
  // between this component and the parent component
  const file = useMemo(() => ({ url: fileUrl }), [fileUrl]);
  const options = useMemo(
    () => ({
      httpHeaders: {
        Authorization: `Bearer ${token}`,
      },
    }),
    [token],
  );

  const memoizedSetCurrentPage = useCallback(
    (value: number) => setCurrentPage(value),
    [setCurrentPage],
  );

  return (
    <Document
      file={file}
      options={options}
      onLoadSuccess={onLoadSuccess}
      onError={(err) => {
        devlog('Error loading PDF', err);
      }}
      loading={
        <div className="w-full flex justify-center">
          <LoadingGradient
            className="rounded-none opacity-60 h-full"
            width={((pageMetadata[0]?.width ?? 0) * scale) / 100}
          />
        </div>
      }>
      <VirtualPageList
        setCurrentPage={memoizedSetCurrentPage}
        pageNumber={pageNumber}
        pageCount={pageCount}
        pageMeta={pageMetadata}
        scale={scale}
      />
    </Document>
  );
};

export default Virtualizer;
