import { useQuery } from '@tanstack/react-query';
import { signInWithToken } from '@/services/firebase/functions/signInWithToken';
import { verifyEmail } from '@/services/firebase/functions/verifyEmail';
import { createAppError } from '@/utils/createAppError';
import { catchAndLogError } from '@/services/firebase/functions/sendErrorLog';

export const useEmailVerify = (confirmationCode: string | null) => {
  const exponentialBackoff = (attemptIndex: number) => Math.min(1000 * 2 ** attemptIndex, 30_000);

  const emailVerifyQuery = useQuery({
    queryKey: ['email_verify'],
    queryFn: () =>
      catchAndLogError(async () => {
        if (!confirmationCode) return null;
        return await verifyEmail(confirmationCode);
      }),
    enabled: !!confirmationCode,
    retry: 10,
    retryDelay: exponentialBackoff,
  });

  const signInWithTokenQuery = useQuery({
    queryKey: ['sign_in_with_token'],
    queryFn: () =>
      catchAndLogError(async () => {
        if (!emailVerifyQuery.data?.isValid || !emailVerifyQuery.data?.idToken) return null;
        await signInWithToken(emailVerifyQuery.data.idToken);
        return null;
      }),
    enabled:
      !!emailVerifyQuery.data?.isValid && !!emailVerifyQuery.data?.idToken && !!confirmationCode,
    retry: 10,
    retryDelay: exponentialBackoff,
  });

  const isSuccess = !!(emailVerifyQuery.isSuccess && signInWithTokenQuery.isSuccess);
  const isError = emailVerifyQuery.isError || signInWithTokenQuery.isError;
  const isLoading = emailVerifyQuery.isLoading || signInWithTokenQuery.isLoading;
  const errorMessage = emailVerifyQuery.error?.message || signInWithTokenQuery.error?.message || '';
  const error = isError ? createAppError('email-verify-failed', errorMessage) : null;

  return {
    isSuccess: isSuccess,
    isLoading: isLoading,
    isError: isError,
    error: error,
  };
};
