import { getBrowserId, getSessionId } from './getSessionId';
import { useSearchStore } from '@/features/Search/state/searchStore';

export function getLogMetadata(): any {
  const localStorageData: Record<string, string | null> = {};
  const cookieData = document.cookie;

  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (!key) continue;
    localStorageData[key] = localStorage.getItem(key);
  }

  const metadata = {
    browserId: getBrowserId(),
    sessionId: getSessionId(),
    location: window.location.href,
    searchStore: useSearchStore.getState(),
    localStorage: localStorageData,
    cookies: cookieData,
  };

  return JSON.parse(JSON.stringify(metadata));
}
