import { KeyboardEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import posthog from 'posthog-js';
import { getAuth } from 'firebase/auth';
import { AuthBackground } from '../../components/AuthBackground';
import { AuthContainerCard } from '../../components/AuthContainerCard';
import { AuthDescription } from '../../components/AuthDescription';
import { AuthError } from '../../components/AuthError';
import { AuthFooter } from '../../components/AuthFooter';
import { AuthInputCard } from '../../components/AuthInputCard';
import { AuthLogo } from '../../components/AuthLogo';
import { AuthSubmitButton } from '../../components/AuthSubmitButton';
import AuthTextInput from '../../components/AuthTextInput';
import { useEmailVerify } from '../../hooks/useEmailVerify';
import AuthPasswordInput from '../../components/AuthPasswordInput.tsx';
import { login } from '@/services/firebase/functions/login';
import { AppError } from '@/common/ErrorMessage/ErrorMessage';
import { Icons } from '@/assets';
import { catchAppError } from '@/utils/createAppError';
import devlog from '@/utils/devlog';
import { sendErrorLog } from '@/services/firebase/functions/sendErrorLog';
import { POSTHOG_EVENT } from '@/services/posthog/events.ts';

export const LoginPage = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isLoginPending, setIsLoginPending] = useState<boolean>(false);
  const [loginError, setLoginError] = useState<AppError | null>(null);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const loginClick = () => {
    setIsLoginPending(true);
    setLoginError(null);
    login(email, password)
      .then(() => {
        const auth = getAuth();
        if (auth.currentUser && !auth.currentUser.emailVerified) {
          navigate(`/auth/confirm-email?email=${auth.currentUser.email}`);
        }
        posthog.capture(POSTHOG_EVENT.LOGIN, { email: email });
      })
      .catch((error) => {
        sendErrorLog(error);
        devlog(error);
        setIsLoginPending(false);
        setLoginError(catchAppError(error));
        posthog.capture(POSTHOG_EVENT.LOGIN_ERROR, { error: error, email: email });
      });
  };

  const onKeyDownPassword: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === 'Enter') {
      loginClick();
    }
  };

  const searchParams = new URLSearchParams(location.search);
  const confirmationCode = searchParams.get('confirmation');
  const { error: emailVerifyError } = useEmailVerify(confirmationCode);

  const isLoading = isLoginPending;
  const error = loginError || emailVerifyError;

  return (
    <AuthBackground>
      <AuthContainerCard>
        <AuthInputCard>
          <div className="h-12" />
          <AuthLogo />
          <AuthDescription text={t('loginPage.description')} />
          <AuthTextInput
            label={t('common.email')}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            id="email"
            value={email}
            placeholder={t('common.emailPlaceholder')}
            Icon={Icons.Mail}
            isError={!!error}
          />
          <div className="h-5" />
          <AuthPasswordInput
            label={t('common.password')}
            placeholder={t('common.passwordPlaceholder')}
            id="password"
            value={password}
            isError={!!error}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={onKeyDownPassword}
          />
          <div className="h-1" />
          <div className="flex items-center justify-between">
            <AuthError error={error} />
            <button
              onClick={() => {
                navigate('/auth/forgot-password');
              }}
              className="text-xs font-medium text-black hover:underline">
              {t('loginPage.forgotPassword')}
            </button>
          </div>
          <div className="h-16" />
          <AuthSubmitButton showLoading={isLoading} onClick={loginClick}>
            {t('loginPage.login')}
          </AuthSubmitButton>
          <div className="h-12" />
        </AuthInputCard>
      </AuthContainerCard>
      <AuthFooter />
    </AuthBackground>
  );
};
