import posthog from 'posthog-js';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import SearchBarDropdownOption from './SearchBarDropdownOption';
import { setParams, setQuery } from '../../state/actions/searchParams';
import { useSearchStore } from '../../state/searchStore';
import { Icons } from '@/assets';
import ErrorMessage from '@/common/ErrorMessage/ErrorMessage';
import { useCompletionSuggestions } from '@/services/api/actions/getCompletionSuggestions';
import { useDocumentSuggestions } from '@/services/api/actions/getDocumentSuggestions';
import { useRecentSearches } from '@/services/firebase/functions/getRecentSearches';
import { POSTHOG_EVENT } from '@/services/posthog/events';
import { SearchParams } from '@/types/api';

type Props = {
  initialQuery?: string;
  onSearch: () => void;
  selectDocument: (documentId: string) => void;
  disabled?: boolean;
  noTextAnimation?: boolean;
  onOpenHistoryModal: () => void;
};

type DropDownOption =
  | {
      type: 'recent';
      params: SearchParams;
      searchId: string;
    }
  | {
      type: 'document';
      title: string;
      legalId: string;
      documentId: string;
    }
  | {
      type: 'completion';
      suggestion: string;
    };

const LINE_HEIGHT = 23;
const MAX_HEIGHT = 23 * 6;

export const SearchBar = (props: React.PropsWithChildren<Props>) => {
  const navigate = useNavigate();
  const { onSearch, disabled, selectDocument, noTextAnimation, onOpenHistoryModal } = props;
  const { t } = useTranslation();
  const [textareaHeight, setTextareaHeight] = useState(LINE_HEIGHT);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState<number | null>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const query = useSearchStore((state) => state.currentParams.query);
  const isCreatingSearch = useSearchStore((state) => state.isCreatingSearch);
  const [showAnimation, setShowAnimation] = useState(false);

  const [isFocused, setIsFocused] = useState(false);
  const error = useSearchStore((state) => state.error);
  const activeDataRole = useSearchStore((state) => state.currentParams.dataRole);

  const { data: documentSuggestions } = useDocumentSuggestions(query, activeDataRole, isFocused);
  const { data: completionSuggestions } = useCompletionSuggestions(
    query,
    activeDataRole,
    isFocused,
  );
  const { data: recentSearches } = useRecentSearches(5);

  useEffect(() => {
    if (isCreatingSearch) {
      setShowAnimation(true);
      setTimeout(() => {
        setShowAnimation(false);
      }, 2000);
    }
  }, [isCreatingSearch]);

  const dropdownOptions = useMemo<DropDownOption[]>(() => {
    if (!isFocused) return [];
    const options: DropDownOption[] = [];

    if (recentSearches && query.trim().length == 0) {
      options.push(
        ...recentSearches.map(({ params, searchId }) => ({
          type: 'recent' as const,
          params,
          searchId,
        })),
      );
      return options;
    }

    if (documentSuggestions?.completions) {
      options.push(
        ...documentSuggestions.completions.map(({ title, legal_id, document_id }) => ({
          type: 'document' as const,
          title,
          legalId: legal_id,
          documentId: document_id,
        })),
      );
    }

    if (completionSuggestions?.completions) {
      options.push(
        ...completionSuggestions.completions.map(({ suggestion }) => ({
          type: 'completion' as const,
          suggestion,
        })),
      );
    }

    return options;
  }, [recentSearches, documentSuggestions, completionSuggestions, isFocused, query]);

  useEffect(() => {
    updateTextareaHeight();
  }, [query]);

  const updateTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = `${LINE_HEIGHT}px`;
      const height = Math.min(textarea.scrollHeight, MAX_HEIGHT);
      const heightShouldBe = Math.floor(height / LINE_HEIGHT) * LINE_HEIGHT;
      textarea.style.height = `${heightShouldBe}px`;
      setTextareaHeight(heightShouldBe);
    }
  };

  const onChangeTextarea = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setQuery(e.target.value);
    setSelectedOptionIndex(null);
    setTextareaHeight(textareaRef.current?.clientHeight || 0);
    updateTextareaHeight();
  };

  const selectOption = (option: DropDownOption) => {
    switch (option.type) {
      case 'recent':
        posthog.capture(POSTHOG_EVENT.SEARCH_HISTORY_SELECT, {
          searchParams: option.params,
          searchId: option.searchId,
          query,
        });
        setParams(option.params);
        navigate(`/search/${option.searchId}`);
        break;
      case 'document':
        posthog.capture(POSTHOG_EVENT.SEARCH_DOCUMENT_SUFGGESTION_SELECT, {
          legalId: option.legalId,
          documentId: option.documentId,
          query,
        });
        selectDocument(option.documentId);
        break;
      case 'completion':
        posthog.capture(POSTHOG_EVENT.SEARCH_COMPLETION_SUGGESTION_SELECT, {
          suggestion: option.suggestion,
          query,
        });
        setQuery(option.suggestion);
        onSearch();
        break;
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.shiftKey) {
      return;
    }

    switch (e.key) {
      case 'ArrowDown':
        setSelectedOptionIndex((selected) =>
          selected == null ? 0 : selected == dropdownOptions.length - 1 ? null : selected + 1,
        );
        e.preventDefault();
        break;
      case 'ArrowUp':
        setSelectedOptionIndex((selected) =>
          selected == null ? dropdownOptions.length - 1 : selected == 0 ? null : selected - 1,
        );
        e.preventDefault();
        break;
      case 'Enter':
        e.preventDefault();
        if (selectedOptionIndex !== null) {
          selectOption(dropdownOptions[selectedOptionIndex]);
        } else if (query.trim().length > 0) {
          onSearch();
          textareaRef.current?.blur();
        }
        break;
    }
  };

  const onFocus = () => {
    setIsFocused(true);
  };

  const onBlur = () => {
    setTimeout(() => setIsFocused(false), 500);
    setSelectedOptionIndex(null);
  };

  const onSearchClick = () => {
    if (query.trim().length > 0) {
      onSearch();
    }
  };

  return (
    <div className="py-4 relative">
      <div style={{ height: textareaHeight }} />

      <div
        className={`absolute top-0 flex flex-col w-full px-5 py-4 rounded-[28px] text-qura-neutral-jet bg-white text-sm shadow-qura z-20 `}>
        <div className={`flex relative gap-[10px] items-center border-green-500`}>
          <button onClick={onSearchClick} title="Search">
            <Icons.NewSearch
              className={isCreatingSearch && noTextAnimation ? 'animate-pulse' : ''}
            />
          </button>

          <textarea
            ref={textareaRef}
            disabled={disabled}
            onFocus={onFocus}
            onBlur={onBlur}
            style={{ lineHeight: `${LINE_HEIGHT}px` }}
            className={`flex-1 resize-none bg-transparent outline-none border-none ${showAnimation && !noTextAnimation ? 'shine-text' : ''}`}
            value={query}
            onChange={onChangeTextarea}
            onKeyDown={handleKeyDown}
            placeholder={t('searchBar.placeholder')}
          />
          {error && (
            <div className="flex items-center absolute top-0 bottom-0 right-0 pb-0.5">
              <ErrorMessage
                error={error}
                className="text-center text-qura-red/80 font-semibold px-4 py-1 tracking-wide text-[15px] bg-qura-neutral-ghost rounded-full"
              />
            </div>
          )}
        </div>

        {dropdownOptions.length > 0 && <div className="h-[1px] w-full bg-gray-200 mt-3 mb-1" />}

        {dropdownOptions.some((o) => o.type == 'recent') && (
          <div>
            <p className="text-tiny mt-2 text-qura-neutral-balanced">
              {t('searchBar.searchHistory')}
            </p>
            {dropdownOptions.map(
              (option, index) =>
                option.type == 'recent' && (
                  <SearchBarDropdownOption
                    key={option.params.query}
                    selected={selectedOptionIndex === index}
                    title={option.params.query}
                    onClick={() => selectOption(option)}
                    titleIcon="Clock"
                  />
                ),
            )}
            <button
              onClick={onOpenHistoryModal}
              className="hover:underline font-normal text-xs text-qura-neutral-balanced">
              <div className="flex items-center">
                <p className="text-tiny">{t('searchBar.seeAll')}</p>
                <Icons.Arrow className="-rotate-90 ml-1 inline w-[12px]" />
              </div>
            </button>
          </div>
        )}

        {dropdownOptions.some((o) => o.type == 'document') && (
          <div>
            <p className="text-tiny mt-2 text-qura-neutral-balanced">
              {t('searchBar.documentMatch')}
            </p>
            {dropdownOptions.map(
              (option, index) =>
                option.type == 'document' && (
                  <SearchBarDropdownOption
                    key={option.legalId + index}
                    selected={selectedOptionIndex === index}
                    title={option.title}
                    tag={option.legalId}
                    tagIcon={'Document'}
                    onClick={() => selectOption(option)}
                  />
                ),
            )}
          </div>
        )}

        {dropdownOptions.some((o) => o.type == 'completion') && (
          <div>
            <p className="text-tiny mt-2 text-qura-neutral-balanced">
              {t('searchBar.suggestions')}
            </p>
            {dropdownOptions.map(
              (option, index) =>
                option.type == 'completion' && (
                  <SearchBarDropdownOption
                    key={option.suggestion + index}
                    selected={selectedOptionIndex === index}
                    title={option.suggestion}
                    titleIcon={'ArrowTiltedUpRight'}
                    onClick={() => selectOption(option)}
                  />
                ),
            )}
          </div>
        )}
      </div>
    </div>
  );
};
