/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, MouseEventHandler, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icons } from '@/assets/index.ts';
import { useDatabaseInfo } from '@/services/api/actions/getDatabaseInfo.ts';

interface IDatabaseInfoModal {
  onClose: () => void;
}

export interface IDataBaseSource {
  documentCount: number;
  weight: number;
  label: string;
  startDate: number[];
  endDate: number[];
  subSources: IDataBaseSource[] | null;
}

const DatabaseInfoModal: FC<IDatabaseInfoModal> = ({ onClose }) => {
  const { isFetching, data } = useDatabaseInfo();
  const { t } = useTranslation();

  useEffect(() => {
    document.body.classList.add('overflow-hidden');
    return () => document.body.classList.remove('overflow-hidden');
  }, []);

  return (
    <>
      <div className="fixed z-20 inset-0 flex items-center justify-center">
        <div className="fixed inset-0 bg-black/50" onClick={onClose} />
        <div className="flex flex-col w-[800px] h-[500px] max-h-[90%] max-w-[90%] px-7 py-7 bg-white rounded-[28px] shadow-lg relative">
          <h2 className=" text-lg font-medium">{t('databaseInformation.databaseInformation')}</h2>
          <div className="flex flex-col flex-1 overflow-y-auto">
            {isFetching ? (
              <div className="flex flex-1 justify-center items-center">
                <p className="tracking-wide animate-pulse">{t('common.loading')}</p>
              </div>
            ) : data?.sources ? (
              <SourceList sources={data.sources} />
            ) : (
              <p className="text-qura-red text-m">{t('errors.unknown')}</p>
            )}
          </div>

          <button
            className="absolute top-6 right-7 text-gray-500 hover:text-gray-700 focus:outline-none"
            onClick={onClose}>
            <Icons.Close />
          </button>
        </div>
      </div>
    </>
  );
};

export default DatabaseInfoModal;

const SourceList: React.FC<{ sources: IDataBaseSource[] }> = ({ sources }) => {
  const sortedSources = useMemo(() => sources.sort((a, b) => a.weight - b.weight), [sources]);

  return (
    <div className="flex flex-col items-stretch self-stretch gap-4 mt-10">
      {sortedSources.map((source, index: number) => (
        <SourceItem key={index} source={source} />
      ))}
    </div>
  );
};

const SourceItem: React.FC<{ source: IDataBaseSource }> = ({ source }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [subContainerHeight, setSubContainerHeight] = useState<number>(0);
  const subContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      setSubContainerHeight(subContainerRef.current?.clientHeight ?? 0);
    };

    const resizeObserver = new ResizeObserver(handleResize);

    if (subContainerRef.current) {
      resizeObserver.observe(subContainerRef.current);
    }

    return () => {
      if (subContainerRef.current) {
        resizeObserver.unobserve(subContainerRef.current);
      }
    };
  }, [subContainerRef]);

  const handleSourceItemStatus: MouseEventHandler<HTMLDivElement> = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`flex flex-col`}>
      <div
        onClick={source.subSources ? handleSourceItemStatus : undefined}
        className={`${isOpen ? 'bg-qura-neutral-ghost' : ''} flex items-center justify-between hover:bg-backgroundColor-gray cursor-pointer py-1 px-2 mr-2 rounded`}>
        <div className="flex gap-2 items-center">
          <p className="text-qura-neutral-jet text-sm leading-[16px]">{source.label}</p>
          <p className="text-qura-neutral-balanced text-xs font-thin leading-[14px]">
            {`${new Date(source.startDate[0]!, source.startDate[1]!, source.startDate[2]).toLocaleDateString()} - ${new Date(source.endDate[0]!, source.endDate[1]!, source.endDate[2]).toLocaleDateString()}`}
          </p>
        </div>
        <div className="flex gap-1  items-center justify-between">
          <Icons.Document className="" />
          <p className="text-qura-neutral-jet text-xs w-20">
            {new Intl.NumberFormat('en-US', {
              style: 'decimal',
              useGrouping: true,
              minimumFractionDigits: 0,
            })
              .format(source.documentCount)
              .replace(/,/g, ' ')}
          </p>
          <Icons.Arrow
            className="transition-transform mx-2"
            style={{
              transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
              opacity: source.subSources ? '1' : '0',
            }}
          />
        </div>
      </div>
      <div
        className="relative overflow-hidden transition-height"
        style={{ height: isOpen ? subContainerHeight : 0 }}>
        <div
          className={'absolute left-0 right-0 flex flex-col ml-4 gap-2 pt-3'}
          ref={subContainerRef}>
          {source.subSources?.map((item) => <SourceItem key={item.label} source={item} />)}
        </div>
      </div>
    </div>
  );
};
