/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MatchScore } from '../MatchScore/MatchScore';
import { Icons } from '@/assets';
import { SearchProgress, SearchResult } from '@/types/api';
import { SearchProgressDeveloperView } from '@/utils/developerMode';

export type SearchStatusType = SearchResult['progress']['stage'];

const DebounceValue = ({
  targetValue,
  increment,
  time,
}: {
  targetValue: number;
  increment: number;
  time: number;
}) => {
  const [currentValue, setCurrentValue] = useState(targetValue);

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;

    if (targetValue !== currentValue) {
      interval = setInterval(() => {
        setCurrentValue((currentValue) => Math.min(currentValue + increment, targetValue));
        if (currentValue >= targetValue) {
          clearInterval(interval);
          interval = undefined;
        }
      }, time);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [targetValue]);

  return (
    <p className="text-xs text-center py-0.5 min-w-9 rounded text-qura-neutral-jet bg-qura-neutral-ghost px-2">
      {new Intl.NumberFormat('en-US', {
        style: 'decimal',
        useGrouping: true,
        minimumFractionDigits: 0,
      })
        .format(Math.ceil(currentValue))
        .replace(/,/g, ' ')}
    </p>
  );
};

type SearchStatusProps = {
  foundDocuments: number;
  progress: SearchProgress | null;
};
const SearchStatus: FC<SearchStatusProps> = ({ foundDocuments, progress }) => {
  const { t } = useTranslation();

  const renderChains = () => {
    if (progress?.stage === null) {
      return (
        <>
          <ChainLink title={''} chainStatus={'not-started'} />
          <ChainLink title={''} chainStatus={'not-started'} />
          <ChainLink title={''} isLast chainStatus={'not-started'} />
        </>
      );
    }

    if (progress?.stage === 'searching') {
      return (
        <>
          <ChainLink title={t('searchStatus.searchingDatabases')} chainStatus={'pending'} />
          <ChainLink title={t('searchStatus.analyzingAndRanking')} chainStatus={'not-started'} />
          <ChainLink title={t('searchStatus.done')} isLast chainStatus={'not-started'} />
        </>
      );
    }

    if (progress?.stage === 'analyzing') {
      return (
        <>
          <ChainLink title={t('searchStatus.searchingDatabases')} chainStatus={'completed'} />
          <ChainLink title={t('searchStatus.analyzingAndRanking')} chainStatus={'pending'} />
          <ChainLink title={t('searchStatus.done')} isLast chainStatus={'not-started'} />
        </>
      );
    }

    if (progress?.stage === 'done') {
      return (
        <>
          <ChainLink title={t('searchStatus.searchingDatabases')} chainStatus={'completed'} />
          <ChainLink title={t('searchStatus.analyzingAndRanking')} chainStatus={'completed'} />
          <ChainLink title={t('searchStatus.done')} isLast chainStatus={'completed'} />
        </>
      );
    }

    return (
      <>
        <ChainLink title={t('searchStatus.searchingDatabases')} chainStatus={'pending'} />
        <ChainLink title={t('searchStatus.analyzingAndRanking')} chainStatus={'not-started'} />
        <ChainLink title={t('searchStatus.done')} isLast chainStatus={'not-started'} />
      </>
    );
  };

  return (
    <div className="p-6 h-fit pointer-events-auto flex flex-col rounded-md shadow-qura bg-white text-xs w-filter bg-qura-white relative group">
      <div className="flex items-center justify-between mb-4">
        <p className="text-xs mt-0.5 text-qura-neutral-jet">{t('searchStatus.searchScore')}</p>
        <MatchScore
          score={progress?.searchScore === 0 ? null : (progress?.searchScore ?? null)}
          className="text-xs text-center w-9 py-0.5 rounded pl-0.5"
        />
      </div>
      <div className="flex items-center justify-between mb-4">
        <p className="text-xs mt-0.5 text-qura-neutral-jet">{t('searchStatus.documentsFound')}</p>
        <p className="text-xs text-center w-9 py-0.5 bg-qura-neutral-ghost rounded text-qura-neutral-jet">
          {foundDocuments}
        </p>
      </div>

      <div className="flex items-center justify-between mb-4">
        <p className="text-xs mt-0.5 text-qura-neutral-jet">
          {t('searchStatus.documentsSearched')}
        </p>
        <DebounceValue
          targetValue={progress?.searchedDocuments ?? 0}
          increment={
            progress?.stage === 'done'
              ? (progress?.searchedDocuments ?? 0)
              : (progress?.searchedDocuments ?? 0) / 50
          }
          time={progress?.searchedDocuments ? 50 : 0}
        />
      </div>

      <div className="h-4" />
      {renderChains()}
      <SearchProgressDeveloperView data={progress} />
    </div>
  );
};

const ChainLink: FC<{
  title: string;
  chainStatus: 'pending' | 'completed' | 'not-started';
  isLast?: boolean;
}> = ({ chainStatus, isLast, title }) => {
  const { t } = useTranslation();

  const getOuterCircleStyles = () => {
    const regularStyles = 'flex items-center justify-center rounded-full w-7 h-7 border-2 ';

    let changedStyles = '';

    switch (chainStatus) {
      case 'pending':
        changedStyles = 'border-qura-neutral-jet';
        break;
      case 'completed':
        changedStyles = 'bg-qura-neutral-jet border-qura-neutral-jet';
        break;
      case 'not-started':
        changedStyles = 'bg-white border-qura-neutral-silver';
        break;
    }
    return `${regularStyles} ${changedStyles}`;
  };

  const getInnerCircleStyles = () => {
    const regularStyles = 'flex items-center justify-center rounded-full';

    let changedStyles = '';

    switch (chainStatus) {
      case 'pending':
        changedStyles = 'w-5 h-5 bg-qura-neutral-jet animate-pulse';
        break;
      case 'completed':
        changedStyles = '';
        break;
      case 'not-started':
        changedStyles = '';
        break;
    }

    return `${regularStyles} ${changedStyles}`;
  };

  const getLineStyles = (isTop: boolean) => {
    const regularStyles = `w-[3px] h-[33px] rounded-[4px] ${isTop ? 'translate-y-1' : ''}`;

    let changedStyles = '';

    switch (chainStatus) {
      case 'pending':
        changedStyles = isTop ? 'bg-qura-neutral-jet' : 'bg-qura-neutral-silver';
        break;
      case 'completed':
        changedStyles = 'bg-qura-neutral-jet';
        break;
      case 'not-started':
        changedStyles = 'bg-qura-neutral-silver';
        break;
    }

    return `${regularStyles} ${changedStyles}`;
  };

  const renderStatus = () => {
    switch (chainStatus) {
      case 'pending':
        return t('searchStatus.pending');

      case 'completed':
        return t('searchStatus.completed');

      case 'not-started':
        return '';
    }
  };

  return (
    <div className="flex flex-col items-start justify-start mb-2">
      <div className="flex items-center h-10 gap-3">
        <div className={getOuterCircleStyles()}>
          <div className={getInnerCircleStyles()}>
            {chainStatus === 'completed' && <Icons.Checked className="text-white" />}
          </div>
        </div>
        <div className="">
          <p className={'text-sm'}>{title}</p>
          {!isLast && <p className="text-xs font">{renderStatus()}</p>}
        </div>
      </div>
      {!isLast && (
        <div className="ml-[12px]">
          <div className={`${getLineStyles(true)}`} />
          <div className={`${getLineStyles(false)}`} />
        </div>
      )}
    </div>
  );
};

export default SearchStatus;
