import { useEffect, useState } from 'react';

import { PDFReaderTopBar } from './PDFReaderTopBar';
import Virtualizer, { PageNumber } from './Virtualizer';
import { DocumentProperties } from '@/types/api';
import { Icons } from '@/assets';

interface PDFReaderProps {
  withHighlightingUrl?: string;
  withoutHighlightingUrl: string;
  documentProperties?: DocumentProperties;
  pageNumber: PageNumber;
  legalId?: string;
}

const PDFReader = (props: PDFReaderProps) => {
  const { withHighlightingUrl, withoutHighlightingUrl, pageNumber, documentProperties } = props;
  const [scale, setScale] = useState(100);
  const [currentPage, setCurrentPage] = useState(pageNumber.number);
  const [scrollToPage, setScrollToPage] = useState(pageNumber);
  const [virtualizerLoaded, setVirtualizerLoaded] = useState(false);

  useEffect(() => {
    setScrollToPage(pageNumber);
  }, [pageNumber]);

  return (
    <>
      <div className="grid grid-cols-1 grid-rows-[3.5rem_1fr] relative rounded-xl shadow-qura h h-full w-full bg-white overflow-clip">
        <PDFReaderTopBar
          legalId={documentProperties?.legalId ?? '-'}
          title={documentProperties?.title ?? 'missing title'}
          scale={scale}
          setScale={setScale}
          currentPage={currentPage}
          setScrollToPage={setScrollToPage}
          documentProperties={documentProperties}
          withHighlightingUrl={withHighlightingUrl}
          withoutHighlightingUrl={withoutHighlightingUrl}
        />
        {documentProperties && (
          <Virtualizer
            pageNumber={scrollToPage}
            fileUrl={withHighlightingUrl ?? withoutHighlightingUrl}
            pageCount={documentProperties.pageCount}
            pageMetadata={documentProperties.pageProperties}
            setCurrentPage={setCurrentPage}
            scale={(scale / 100) * documentProperties.scaleFactor}
            onLoadSuccess={() => setVirtualizerLoaded(true)}
          />
        )}
        {(!virtualizerLoaded || !documentProperties) && (
          <div className="absolute bg-white top-[3.5rem] bottom-0 left-0 right-0 flex items-center justify-center">
            <Icons.LogoText className="h-6 animate-pulse" />
          </div>
        )}
      </div>
    </>
  );
};

export default PDFReader;
