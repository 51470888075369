export const appErrorTypes = [
  'network',
  'unknown',
  'invalid-credentials',
  'unimportant',
  'identical-request',
  'invalid-signup-token',
  'message',
  'email-already-used',
  'email-not-confirmed',
  'no-results',
  'load-resources-failed',
  'email-verify-failed',
  'email-verify-success',
  'websocket-error',
  'ws-validation-error',
  'unauthenticated',
] as const;

export type AppErrorType = (typeof appErrorTypes)[number];

export const createAppError = (type: AppErrorType, message: string) => {
  return {
    type,
    message,
  };
};

export type CatchAppErrorOptions = {
  ignoreOnLine: boolean;
};

export const catchAppError = (error: unknown, options?: CatchAppErrorOptions) => {
  let type: AppErrorType = 'unknown';
  let message: string = 'Unknown error';

  if (typeof error === 'string') {
    message = error;
  }

  if (error instanceof Error) {
    message = error.message;
  } else if (
    typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    typeof error.message === 'string'
  ) {
    message = error.message;
  } else {
    message = JSON.stringify(error);
  }

  if (
    typeof error === 'object' &&
    error !== null &&
    'type' in error &&
    typeof error.type === 'string' &&
    appErrorTypes.includes(error.type as AppErrorType)
  ) {
    type = error.type as AppErrorType;
  }

  if (type === 'unknown' && !options?.ignoreOnLine && !navigator.onLine) {
    type = 'network';
  }

  return createAppError(type, message);
};
